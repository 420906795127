import { React, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./header.css";
import logo from "assets/images/logo.svg";
import MenuIcon from "assets/images/burger-menu.svg";
import MenuIconDark from "assets/images/burger-menu-dark.svg";
import CloseIcon from "@mui/icons-material/Close";
import ThemeToggle from "../ThemeToggle";
import { useTheme } from "../../context/ThemeContext";

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isExperienceHovered, setExperienceHovered] = useState(false);
  // const [isDarkMode, setIsDarkMode] = useState(false);
  const sidebarRef = useRef();
  const sidebarRefList = useRef();
  const { isDarkMode } = useTheme();
  const handleMobileMenu = () => {
    // console.log("Mobile Menu Clicked");
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target) &&
      sidebarRefList.current &&
      !sidebarRefList.current.contains(event.target)
    ) {
      setMobileMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeMenuBar = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div>
      <header className="header" id="header">
        {/* <img src={logo} alt="Website Logo" /> */}
        <Link to="/">
          <h1>Vincent.</h1>
        </Link>
        <div className="navigation">
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          {/* Experience Dropdown */}
          <div
            className="dropdown-container"
            onMouseEnter={() => setExperienceHovered(true)}
            onMouseLeave={() => setExperienceHovered(false)}>
            <span className="dropdown-header">Experience</span>
            <div
              className={`dropdown-menu ${
                isExperienceHovered ? "visible" : ""
              }`}>
              <Link to="/experience/professional">Professional</Link>
              <Link to="/experience/other">Other</Link>
            </div>
          </div>
          {/* <Link to="/experience">Experience</Link> */}
          {/* <Link to="/experience">Other</Link> */}
          <Link to="/education">Education</Link>
          {/* <Link to="/projects">Projects</Link> */}

          {/* <Link to="/skills">Tools & Skills</Link> */}

          <Link to="/gallery">Gallery</Link>
          <Link to="/contact">Contact</Link>
          <ThemeToggle />
        </div>
        <div ref={sidebarRef} className="menu-icon" onClick={handleMobileMenu}>
          <img src={isDarkMode ? MenuIcon : MenuIconDark} alt="Menu Icon" />
        </div>
      </header>
      <nav
        ref={sidebarRefList}
        className={`navigation-mobile ${isMobileMenuOpen ? "open" : ""}`}>
        <ul>
          <li onClick={closeMenuBar}>
            <Link to="/">Home</Link>
          </li>

          <li onClick={closeMenuBar}>
            <Link to="/about">About</Link>
          </li>

          {/* <li onClick={closeMenuBar}>
            <Link to="/experience">Experience</Link>
          </li> */}
          {/* <li className="mobile-dropdown"> */}
          {/* <span onClick={() => setMobileDropdownOpen(!isMobileDropdownOpen)}>
              Experience
            </span> */}
          {/* <ul className={`submenu ${isMobileDropdownOpen ? "open" : ""}`}> */}
          <li onClick={closeMenuBar}>
            <Link to="/experience/professional">Professional Exp.</Link>
          </li>
          <li onClick={closeMenuBar}>
            <Link to="/experience/other">Other Exp.</Link>
          </li>
          <li onClick={closeMenuBar}>
            <Link to="/education">Education</Link>
          </li>
          <li onClick={closeMenuBar}>
            <Link to="/gallery">Gallery</Link>
          </li>
          <li onClick={closeMenuBar}>
            <Link to="/contact">Contact</Link>
          </li>
          <ThemeToggle />
        </ul>
      </nav>
    </div>
  );
};

export default Header;
