import React from "react";
import "./other.css";
import Header from "components/Header";
import EwhLogo from "assets/images/ewh-logo.png";
import SurgicalLampImage from "assets/images/repair_surgical_lamp.jpg";
import CircuitImage from "assets/images/circuit.jpg";
import AfterSignImage from "assets/images/after_signs.png";
import SignsLowImage from "assets/images/signs_low.jpg";
import BigMapAfterImage from "assets/images/after_bigmap.png";
import BigMapBeforeImage from "assets/images/bigmap_before.png";
import AlohaImg from "assets/images/aloha.jpg";

import Footer from "components/Footer";

const Other = () => {
  return (
    <div>
      <Header />
      <div className="experience">
        <h1>OTHER EXPERIENCE</h1>
        <div className="experience-component">
          <div className="experience-component-title">
            <div className="experience-component-title-text">
              <h2>
                VOLUNTEER MEDICAL EQUIPMENT TECHNICIAN - ENGINEERING WORLD
                HEALTH
              </h2>
              <h3>Phnom Penh & Pursat, Cambodia</h3>
              <h3>DEV 2019 - FEB 2020</h3>
            </div>
            <img
              id="EwhLogo"
              src={EwhLogo}
              alt="Engineering World Health Logo"
            />
          </div>

          <ul>
            <li>
              <a
                href="https://www.ewh.org/"
                target="_blank"
                rel="noopener noreferrer">
                Engineering World Health (EWH)
              </a>{" "}
              is a U.S.-based non-profit organization that engages the skills
              and passions of students and professionals to improve healthcare
              delivery in low-income countries. The Cambodia Institute of EWH
              focused on the healthcare delivery condition in Cambodia.
            </li>
            <li>
              I worked in rural hospital Pursat Provincial Referral Hospital.
              Primary work was to tested and repair medical equipment (most of
              them were donated across the world), aiming for increasing the
              quality of local hospitals' medical treatment condition.
            </li>
            <li>
              Provided training for the usage of medical equipment to local
              doctors and biomedical equipment technicians.
            </li>
            <li>
              Attempted to repair 55 medical equipment, and 71% of the equipment
              has been fixed successfully.
            </li>
            <li>
              Repair Highlights: USSR Lamp (1970s), GE v100 Vital Signs Monitor,
              Ceiling Lamp (OR2) and Broken Rheostat Lamp (Daikyo)
            </li>
            <li>
              Re-design Pursat Hospital's Navigation Signs: a brand new 3D
              version navigation package. SketchUp and Photoshop were used
            </li>
          </ul>
          {/* Image Gallery Section */}
          <div className="image-gallery">
            <div className="image-group">
              <img src={SurgicalLampImage} alt="Surgical lamp repair" />
              <p>Repairing a surgical lamp</p>
            </div>

            <div className="image-group">
              <img src={CircuitImage} alt="Circuit board repair" />
              <p>Troubleshooting medical equipment circuitry</p>
            </div>

            <div className="image-group">
              <img src={SignsLowImage} alt="Signage details" />
              <img src={AfterSignImage} alt="Hospital navigation signs" />
              <p>Redesigned hospital signage - before & after</p>
            </div>

            <div className="image-group">
              <img src={BigMapBeforeImage} alt="Original hospital map" />
              <img src={BigMapAfterImage} alt="Updated hospital map" />
              <p>Hospital map redesign - before & after</p>
            </div>
          </div>
        </div>
        <div className="experience-component">
          <div className="experience-component-title">
            <div className="experience-component-title-text">
              <h2>VOLUNTEER ALOHA MEDICAL MISSION'S (AMM) SURGICAL MISSION</h2>
              <h3>Pursat Provincial Referral Hospital, Pursat, Cambodia</h3>
              <h3>JAN 2020</h3>
            </div>
          </div>

          <ul>
            <li>
              <a
                href="https://alohamedicalmission.org/"
                target="_blank"
                rel="noopener noreferrer">
                Aloha Medical Mission (AMM)
              </a>{" "}
              is a nonprofit volunteer organisation based in Honolulu, Hawaii,
              provides free health care to underserved people in the Pacific,
              Asia, and Hawaii. AMM had some operations in Pursat Provincial
              Referral Hospital during my volunteer work at Pursat. Advance
              preparation and ongoing support were needed. Me and my partner
              helped them as much as we could while we worked as an EWH
              technician volunteer.
            </li>
            <li>
              Many pieces of medical equipment were too old and they could be
              broken during operation. Equipment such as ventilators, ceiling
              lights, and head torches were fixed during or immediately after
              operations.
            </li>
          </ul>
          {/* Image Gallery Section */}
          <div className="image-gallery-center">
            <div className="image-group-center">
              <img src={AlohaImg} alt="Aloha Certification" />
              <p>Certificate of Appreciation</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Other;
