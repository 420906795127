import React, { useEffect, useRef } from "react";
import "./gallery.css";
import Header from "components/Header";
import Footer from "components/Footer";
import Grid from "@mui/material/Unstable_Grid2";
import Horticultural from "assets/images/Horticultural.jpg";
import Gaol from "assets/images/gaol.jpg";
import OperaHarbour from "assets/images/opera-harbour.jpg";
import Singapore from "assets/images/singapore.jpg";
import Angkor from "assets/images/angkor.jpg";
import Flinder from "assets/images/flinder.jpg";
import Botany from "assets/images/botany.jpg";
import Floating from "assets/images/floating.jpg";
import Coogee from "assets/images/coogee.jpg";
import Seoul from "assets/images/seoul.jpg";
import Sky from "assets/images/sky.jpg";
import Circular from "assets/images/circular.jpg";
import Krivong from "assets/images/krivong.jpg";
import Cafe from "assets/images/cafe.jpg";
import Sunrise from "assets/images/sunrise.jpg";
import Beach from "assets/images/beach.jpg";
import Bombo from "assets/images/kiama.jpg";

const IndividualImageComponent = ({ title, time, imageSrc, alt, id }) => {
  const imageRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target;
            img.src = img.dataset.src;
            observer.unobserve(img);
          }
        });
      },
      {
        rootMargin: "50px",
      }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }
    };
  }, []);

  return (
    <div className="individual-image-component" id={id}>
      <h3>{title}</h3>
      <p>{time}</p>
      <img
        ref={imageRef}
        data-src={imageSrc}
        src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" // Tiny transparent placeholder
        alt={alt}
        loading="lazy"
      />
    </div>
  );
};

const Gallery = () => {
  const images = [
    {
      title: "Bombo Headland, Bombo, Australia",
      time: "10 December 2024",
      imageSrc: Bombo,
    },
    {
      title: "Horticultural Expo Park, Xi'an, China",
      time: "21 February 2016",
      imageSrc: Horticultural,
      alt: "Horticultural Image",
    },
    {
      title: "Old Melbourne Gaol, Melbourne, Australia",
      time: "19 December 2017",
      imageSrc: Gaol,
      alt: "Gaol Image",
    },
    {
      title: "Sydney Opera House & Harbour Bridge, Sydney, Australia",
      time: "12 November 2017",
      imageSrc: OperaHarbour,
      id: "operaharbour",
      alt: "Sydney Opera House and Harbour Bridge",
    },
    {
      title: "Rain Vortex, Singapore",
      time: "16 December 2019",
      imageSrc: Singapore,
      alt: "Rain Vortex",
    },
    {
      title: "Angkor National Museum, Siem Reap, Cambodia",
      time: "4 January 2020",
      imageSrc: Angkor,
      alt: "Angkor National Museum",
    },
    {
      title: "Flinders St Station, Melbourne, Australia",
      time: "17 December 2017",
      imageSrc: Flinder,
      id: "operaharbour",
      alt: "Flinders St Station",
    },
    {
      title: "Kamay Botany Bay National Park, Sydney, Australia",
      time: "1 January 2021",
      imageSrc: Botany,
      id: "botany",
      alt: "Kamay Botany Bay National Park",
    },
    {
      title: "The Floating Village, Siem Reap, Cambodia",
      time: "4 January 2020",
      imageSrc: Floating,
      alt: "The Floating Village",
    },
    {
      title: "Seoul to Sydney, Flight KE121",
      time: "5 October 2017",
      imageSrc: Seoul,
      alt: "Seoul to Sydney Flight",
    },
    {
      title: "Coogee Beach, Sydney, Australia",
      time: "29 October 2017",
      imageSrc: Coogee,
      alt: "Coogee Beach",
    },
    {
      title: "Sky, Sydney, Australia",
      time: "29 January 2018",
      imageSrc: Sky,
      alt: "Sky in Sydney",
    },
    {
      title: "Circular Quay, Sydney, Australia",
      time: "12 November 2017",
      imageSrc: Circular,
      id: "operaharbour",
      alt: "Circular Quay",
    },
    {
      title: "Krivong (Border city), Cambodia",
      time: "27 December 2019",
      imageSrc: Krivong,
      alt: "Krivong Border City",
    },
    {
      title: "Cafe in Xi'an, China",
      time: "13 August 2017",
      imageSrc: Cafe,
      id: "operaharbour",
      alt: "A Cafe in Xi'an",
    },
    {
      title: "Sunrise in Sihanoukville, Cambodia",
      time: "28 December 2019",
      imageSrc: Sunrise,
      alt: "Sunrise in Sihanoukville",
    },
    {
      title: "Long Reef Beach, Sydney, Australia",
      time: "25 November 2023",
      imageSrc: Beach,
    },
  ];

  return (
    <>
      <Header />
      <div className="gallery">
        {images.map((image, index) => (
          <IndividualImageComponent
            key={index}
            title={image.title}
            time={image.time}
            imageSrc={image.imageSrc}
            alt={image.alt}
            id={image.id}
          />
        ))}
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
