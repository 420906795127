import React from "react";
import "./about.css";
import LaptopIcon from "assets/images/laptop-icon.png";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import UNSWLogo from "assets/images/unsw-logo.png";
import {
  FaJava,
  FaPython,
  FaReact,
  FaNodeJs,
  FaAws,
  FaDocker,
  FaGithub,
} from "react-icons/fa";
import {
  SiSpring,
  SiTypescript,
  SiJavascript,
  SiHtml5,
  SiCss3,
  SiFlutter,
  SiPerl,
  SiCplusplus,
  SiExpress,
  SiAngular,
  SiDjango,
  SiFlask,
  SiNextdotjs,
  SiCircleci,
  SiJenkins,
  SiKubernetes,
  SiPostgresql,
  SiMongodb,
  SiJunit5,
  SiNewrelic,
  SiGrafana,
  SiPostman,
  SiJira,
  SiConfluence,
} from "react-icons/si";
import ProfilePhoto from "assets/images/profile.png";
import Header from "components/Header";
import Footer from "components/Footer";

const About = () => {
  return (
    <div>
      <Header />
      <div className="home-about-me">
        <h1>Software Engineer | Full-stack Developer | Tech Enthusiast</h1>
        {/* <div className="home-about-me-profile-text"> */}
        <p>
          <span class="image">
            <img src={ProfilePhoto} alt="Profile Photo" />
          </span>
        </p>
        <p>
          Hello! My name is Vincent, currently working as a Software Engineer at
          Infomedia - a global company offering SaaS solutions in the automotive
          industry. I hold an Honours degree in Software Engineering from the
          University of New South Wales (UNSW).
          <br />
          <br />
          I am passionate about software development and have a keen interest in
          the latest technologies. I am a self-motivated, hard-working, and
          enthusiastic individual with a strong desire to learn and grow. I
          enjoy working in a team environment and have a proven ability to
          deliver high-quality results.
          <br />
          <br />
          Originally from Xi'an, China, I have made Sydney my home since 2017.
        </p>
        {/* </div> */}
        <br />
        <br />
        <br />
        <h2>My Strength - Tools & Skills</h2>
        <p>
          As a tech lover, I have a strong passion for learning new
          technologies. I am proficient in a range of programming languages and
          tools. Below lists some of my strengths and tools that I like to use.
          <br />
          <br />
          <div className="tech-line">
            Java with Spring, TypeScript/JavaScript with React/Angular/Express,
            <FaJava className="tech-icon" /> <SiSpring className="tech-icon" />{" "}
            <SiTypescript className="tech-icon" />{" "}
            <SiJavascript className="tech-icon" />{" "}
            <FaReact className="tech-icon" />{" "}
            <SiAngular className="tech-icon" />{" "}
            <SiExpress className="tech-icon" />
          </div>
          <div className="tech-line">
            Python with Django/Flask, HTML, CSS/SCSS, Flutter,
            <FaPython className="tech-icon" />{" "}
            <SiDjango className="tech-icon" /> <SiFlask className="tech-icon" />{" "}
            <SiHtml5 className="tech-icon" /> <SiCss3 className="tech-icon" />{" "}
            <SiFlutter className="tech-icon" />
          </div>{" "}
          <div className="tech-line">
            Bash & Perl, C/C++
            <SiPerl className="tech-icon" />{" "}
            <SiCplusplus className="tech-icon" />
          </div>
          <div className="tech-line">
            Frameworks: Spring, Express, React, Angular, Node.js, Express,
            Flask, Django, Next.js
            <SiSpring className="tech-icon" />{" "}
            <SiExpress className="tech-icon" />{" "}
            <FaReact className="tech-icon" />{" "}
            <SiAngular className="tech-icon" />{" "}
            <FaNodeJs className="tech-icon" /> <SiFlask className="tech-icon" />{" "}
            <SiDjango className="tech-icon" />{" "}
            <SiNextdotjs className="tech-icon" />
          </div>
          Tech: REST API, GraphQL API, OAuth2, JWT, Session, SSO
          <div className="tech-line">
            Cloud & DevOps: AWS (ALB, EC2, ECS, ECR, Lambda, API Gateway, SNS,
            CLoudWatch, CloudFront, S3 etc.), CircleCI, Jenkins, Kubernetes,
            Containerisation (Docker)
            <FaAws className="tech-icon" /> <SiCircleci className="tech-icon" />
            <SiJenkins className="tech-icon" />{" "}
            <SiKubernetes className="tech-icon" />{" "}
            <FaDocker className="tech-icon" />
          </div>
          <div className="tech-line">
            Database & Data Processing (ETL): Airflow, Glue, MySQL, PostgreSQL,
            MongoDB, RDS, DynamoDB
            <SiPostgresql className="tech-icon" />{" "}
            <SiMongodb className="tech-icon" />
          </div>
          <div className="tech-line">
            Testing: JUnit, Mockito, JMeter, Gatling
            <SiJunit5 className="tech-icon" />
          </div>
          <div className="tech-line">
            Observability & Monitoring: New Relic, Lacework, Grafana
            <SiNewrelic className="tech-icon" />{" "}
            <SiGrafana className="tech-icon" />
          </div>
          <div className="tech-line">
            Code & Work Management: Postman, Soap, IntelliJ, VS Code, Notion,
            Jira, Confluence, GitHub, GitLab, Bitbucket
            <SiPostman className="tech-icon" /> <SiJira className="tech-icon" />{" "}
            <SiConfluence className="tech-icon" />{" "}
            <FaGithub className="tech-icon" />
          </div>
          Methodologies: Microservice, Agile, TDD, Shift-left testing
        </p>

        <br />
        <br />
        <br />
        <h2>Professional Competence</h2>
        <p>
          My career has honed my expertise in software development, emphasising
          the comprehensive aspects of software implementation and delivery.
          This expertise encompasses a range of areas including front-end and
          back-end development, RESTful API/GraphQL API design, database
          operations, ETL, and DevOps implementation.
          <br />
          <br />
          I am proficient in utilising cloud tools and implementing CI/CD
          pipelines, primarily with AWS and GCP platforms. My responsibilities
          also extend to production oversight and monitoring, utilising tools
          such as New Relic and CloudWatch. I also have interest in cutting-edge
          technologies particularly in fields like Natural Language Processing
          (NLP) and Machine Learning.
          <br />
          <br />
          My journey in technology has been exciting and fulfilling. I love
          delivering products that benefit customer. The mix of technical skills
          I've equipped and the dedication to making a positive impact is at the
          heart of what I do. For more, visit my experience.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default About;
