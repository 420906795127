import React from "react";
import "./experience.css";
import Header from "components/Header";
import InfomediaLogo from "assets/images/infomedia-logo.jpg";
import UNSWLogo from "assets/images/unsw-logo.png";
import SATLogo from "assets/images/sat-logo.jpg";
import PearlerLogo from "assets/images/pearler-logo.png";
import MSALogo from "assets/images/MSALogo.png";
import IceBreakLogo from "assets/images/icebreak.svg";
import JrAcademyLogo from "assets/images/jr-academy-logo.svg";
import Footer from "components/Footer";

const Experience = () => {
  return (
    <div>
      <Header />
      <div className="experience">
        <h1>PROFESSIONAL EXPERIENCE</h1>
        <div className="experience-component">
          <div className="experience-component-title">
            <div className="experience-component-title-text">
              <h2>SOFTWARE | DEVOPS ENGINEER - INFOMEDIA</h2>
              <h3>NOV 2021 - CURRENT</h3>
            </div>
            <img id="InfomediaLogo" src={InfomediaLogo} alt="Infomedia Logo" />
          </div>

          <ul>
            <li>
              Implemented APIs and business logic using Java with Spring
              framework and TypeScript/JavaScript with Express.
            </li>
            <li>
              Docker containerisations for multi-environments, applying test
              automation, integration test, and stress testing, and unit
              testing.
            </li>
            <li>
              Frontend development of UI and components using
              TypeScript/JavaScript with React framework, Redux for state
              management, applying testing with Jest and Cypress, and performing
              Component-Driven Development approach. Integrated with library
              such as MUI and Bootstrap.
            </li>
            <li>
              Led the ISO compliance project to meet data and audit requirement,
              including server monitoring and observability, PII data
              encryption, production logging, server & database backup (Bash
              script), restoration, and patching strategy. Communicated with
              external service provider to acquire information and achieve
              goals.
            </li>
            <li>
              Developed and utilised CI/CD pipeline via CircleCI/Bamboo and
              CloudFormation to automate infrastructure and deployment
              processes.
            </li>
            <li>
              Integrated database with JPA/Hibernate/TypeORM and auditing, Redis
              for caching & authentication (OAuth2/JWT/session). Utilised tools
              such as Postman and SoapUI for development.
            </li>
            <li>
              Built Airflow ETL pipelines in Python on AWS (EC2/ECS/Glue/ECR).
              Processed data with Spark and Scala for diverse data formats (e.g.
              Parquet/CSV etc) and data sources (MongoDB/DynamoDB
              /MySQL/External APIs). Automated workflows in Docker-managed
              environments with Bash scripts, CloudFormation and CloudWatch.
            </li>
            <li>
              Implemented and configured monitor tools such as New Relic,
              Lacework, Grafana, and CloudWatch with Athena to enhance
              production monitoring, observability, and vulnerability scans.
            </li>
            <li>
              Designed and enhanced team's code quality by implementing
              branch-specific vulnerability management system using CodeQL.
              Resolved high-priority vulnerabilities and ensured the team's
              committed code met quality standard.
            </li>
            <li>
              Achievements: Won 1st place in the company's Hackathon 2024 for
              optimising legacy product by integrating event-driven AWS Lambda
              serverless function with SES, IAM role, SNS, and S3 for
              on-premises product's data processing and reporting, significantly
              reducing server load. Nominee of company Above & Beyond Awards for
              the first quarter 2025.
            </li>
            <li>
              Fast promotion from Intern to Graduate SE and then Mid-level SE.
              Recognised for strong performance, impactful contributions,
              ownership and a positive attitude with continuous learning
              mindset.
            </li>
            <li>
              Provided on-call support to promptly diagnose and resolve critical
              issues, and deployed servers to meet customer requirements.
              Prioritised comprehensive documentation in Confluence and actively
              supported team members in an Agile environment, using Jira for
              effective task management.
            </li>
          </ul>
        </div>
        <div className="experience-component">
          <div className="experience-component-title">
            <div className="experience-component-title-text">
              <h2>RESEARCH STUDENT - UNSW</h2>
              <h3>FEB 2021 - DEC 2021</h3>
            </div>
            <img id="UNSWLogo" src={UNSWLogo} alt="UNSW Logo" />
          </div>

          <ul>
            <li>
              Researched Text Mining for Financial Forecasting, focusing on the
              influence of market sentiments on the stock market.
            </li>
            <li>
              Analysed human-readable financial news fetched from the internet,
              aiming to extract large content for processing, so to increase the
              prediction accuracy.
            </li>
            <li>
              Gained hands-on experience with the ETL process, implementing Data
              Acquisition, Pre-processing (data preparation and cleaning) in
              Python3 using Pandas, NumPy, Scikit-learn, and NLTK.
            </li>
            <li>
              Trained, compared, and evaluated results produced by 4 models:
              MLPClassifier Model, Random Forest Classifier Model, Passive
              Aggressive Classifier Model and Naïve Bayes for Multinomial
              Models.
            </li>
            <li>Received Distinction grade for the Research subject.</li>
            <li>
              Demo slide deck:{" "}
              <a
                href="https://docs.google.com/presentation/d/18TAQidDVvtG7XdAKoTerMJjcxOCxDpHeeBO8d6hso5k/edit?
              usp=sharing">
                https://docs.google.com/presentation/d/18TAQidDVvtG7XdAKoTerMJjcxOCxDpHeeBO8d6hso5k/edit?
                usp=sharing
              </a>
            </li>
          </ul>
        </div>
        <div className="experience-component">
          <div className="experience-component-title">
            <div className="experience-component-title-text">
              <h2>DEVELOPER INTERN - SAT PTY LTD</h2>
              <h3>AUG 2021 - OCT 2021</h3>
            </div>
            <img id="SATLogo" src={SATLogo} alt="SAT Logo" />
          </div>

          <ul>
            <li>
              Designed and built Client's Database locally by preparing and
              managing required information from external organisations.
              Automated processes such as Database query, file generation and
              its submission
            </li>
            <li>
              Relevant areas of knowledge: Database design and creation by
              applying Python script (sqlite3), SQLite and MySQL conversion, XML
              file auto-generation & manipulation, AWS CodeCommit, Git usage
            </li>
          </ul>
        </div>
        <div className="experience-component">
          <div className="experience-component-title">
            <div className="experience-component-title-text">
              <h2>
                WEB APPLICATION DEVELOPER INTERN - PEARLER INVESTMENTS PTY LTD
              </h2>
              <h3>JUN 2020 - NOV 2020</h3>
            </div>
            <img id="PearlerLogo" src={PearlerLogo} alt="Pearler Logo" />
          </div>

          <ul>
            <li>
              Developed prototype for Pearler's Financial Investment Social
              Timeline Web Application, creating the core framework for key
              social networking features.
            </li>
            <li>
              Implemented primary functions such as posting, timeline filtering,
              investment trending, investor following, and personal investment
              analysis & statistics pages.
            </li>
            <li>
              Collaborated with the founder in an agile development process,
              holding weekly meetings to refine the product's design and
              functionality.
            </li>
            <li>
              Key tech: ReactJS, HTML & CSS, Figma, Database Design, Web App
              UX/UI design & its critical thinking.
            </li>
          </ul>
        </div>
        <div className="experience-component">
          <div className="experience-component-title">
            <div className="experience-component-title-text">
              <h2>PROJECT STUDENT - MICROSOFT STUDENT ACCELERATOR</h2>
              <h3>APR 2020 - SEP 2020</h3>
            </div>
            <img id="MSALogo" src={MSALogo} alt="MSA Logo" />
          </div>

          <ul>
            <li>
              Acquired industrial training from Microsoft employees and past
              participants, and used these skills to build static/commercial
              websites
            </li>
            <li>
              Created a multi-page personal profile website, using several
              plugins such as Robo Gallery, Yoast SEO, Elementor, Contact Form 7
              and Google Analytics - MonsterInsights
            </li>
            <li>
              Relevant areas of knowledge: Python, Flask, WordPress Dashboards
              usage and management, Plugins usage, Power Apps, HTML & CSS, SQL
              and Deployment
            </li>
          </ul>
        </div>
        <h1>OTHER EXPERIENCE</h1>
        <div className="experience-component">
          <div className="experience-component-title">
            <div className="experience-component-title-text">
              <h2>CASUAL WEB DEVELOPER - ICE BREAK ESCAPE ROOM</h2>
              <h3>AUG 2023 - CURRENT</h3>
            </div>
            <img
              id="IceBreakLogo"
              src={IceBreakLogo}
              alt="Ice Break Group Logo"
            />
          </div>
          <ul>
            <li>
              Developed and optimised an Escape Room website based on Figma
              UI/UX designs, with a focus on mobile responsiveness, performance
              improvement, and SEO enhancement.
            </li>
            <li>
              Built the site using ReactJS and CSS, following mobile-first
              development approach and creating components and pages based on
              the design requirements.
            </li>
            <li>
              Key features include responsive layout, carousel-style theme
              showcase, lazy loading, responsive website interaction based on
              user input, contact form, Google Maps embedding, and integrated
              booking system (Bookeo) etc.
            </li>
            <li>
              Improved SEO ranking by configuring URL normalization, enhancing
              website accessibility, ensuring compatibility across various
              mobile devices, and code quality.
            </li>
            <li>
              Website:{" "}
              <a href="https://www.icebreakescaperoom.com/">
                https://www.icebreakescaperoom.com/
              </a>
            </li>
          </ul>
        </div>
        <div className="experience-component">
          <div className="experience-component-title">
            <div className="experience-component-title-text">
              <h2>GOEXPERT WEB APPLICATION</h2>
              <h3>JUL 2021 - Nov 2021</h3>
            </div>
            <img id="JrAcademyLogo" src={JrAcademyLogo} alt="JR Academy Logo" />
          </div>
          <ul>
            <li>
              GoExpert is a web application project that connects users with
              professionals through one-on-one appointment bookings.
            </li>
            <li>
              Developed the application using React, TypeScript, HTML5, and CSS3
              with SASS, ensuring code quality using TSLint and Stylelint.
            </li>
            <li>
              Designed and built frontend components, backend services (RESTful
              API in Node.js), and database in MongoDB.
            </li>
            <li>
              Developed and Integrated AWS Lambda for image transformation to
              optimise server load and reduce operation costs.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Experience;
